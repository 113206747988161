import React from "react";
import "./SplashScreen.css"; // Add styles for your splash screen

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <div style={{ position: "relative" }}>
        <div class="spinner">
          <div class="spinner1"></div>
        </div>
        <img src="Subtract.png" alt="Loading..." className="loader" />
      </div>
    </div>
  );
};

export default SplashScreen;
