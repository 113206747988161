import React, { useState, useEffect } from "react";
import { Navbar, Image, Offcanvas, Button } from "react-bootstrap";
import CustomSwitch from "./CutomSwitch";
import "./themes.css";

function NavBar({ theme, toggleTheme }) {
  const [drawer, setDrawer] = useState(false);
  const [isMobile, setIsMobile] = useState(window.outerWidth <= 425);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.outerWidth <= 425);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navbarStyle = {
    backgroundColor: theme === "light" ? "#000000" : "#ffffff",
  };

  const buttonStyle = {
    color: theme === "light" ? "#ffffff" : "#000000",
    marginRight: "25px",
  };

  const offcanvasStyle = {
    backgroundColor: theme === "light" ? "#000000" : "#ffffff",
  };

  const brandStyle = {
    margin: "25px",
    paddingRight: "20px",
    paddingLeft: "20px",
    fontSize: "1.1rem",
    color: theme === "dark" ? "#000000" : "#ffffff",
  };
  const handleScrollToHome = (e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ fontFamily: "Poppins, sans-serif" }}>
      {/* Drawer for small screens */}
      {isMobile ? (
        <>
          <Navbar fixed="top" style={navbarStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginLeft: "25px",
                marginTop: "25px",
                paddingTop: "2px",
              }}
            >
              {theme ===
              "dark" ? (
                <Image
                  width="51"
                  height="60"
                  src="Group 1321314423.png"
                  onClick={handleScrollToHome}
                />
              ) : (
                <Image
                  width="51"
                  height="60"
                  src="Subtract.png"
                  onClick={handleScrollToHome}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                marginLeft: "25px",
              }}
            >
              <CustomSwitch theme={theme} toggleTheme={toggleTheme} />

              <Button
                variant="link"
                onClick={() => setDrawer(true)}
                size="lg"
                style={buttonStyle}
              >
                <i className="bi bi-list" style={{ fontSize: "3rem" }}></i>
              </Button>
            </div>
          </Navbar>
          <Offcanvas
            show={drawer}
            onHide={() => setDrawer(false)}
            placement="start"
            style={offcanvasStyle}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {theme ===
                "dark" ? (
                  <Image width="51" height="60" src="Group 1321314423.png" />
                ) : (
                  <Image width="51" height="60" src="Subtract.png" />
                )}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <br />
              <Navbar.Brand
                href="#home "
                onClick={handleScrollToHome}
                style={{
                  margin: "50px",
                  marginTop: "50px",
                  padding: "25px",
                  fontSize: "2rem",
                  color: theme === "dark" ? "#000000" : "#ffffff",
                }}
              >
                Home
                <br />
                <br />
              </Navbar.Brand>
              <Navbar.Brand
                href="#portfolio"
                style={{
                  margin: "50px",
                  marginTop: "50px",
                  padding: "25px",
                  fontSize: "2rem",
                  color: theme === "dark" ? "#000000" : "#ffffff",
                }}
              >
                Portfolio
                <br />
                <br />
              </Navbar.Brand>
              <Navbar.Brand
                href="#services"
                style={{
                  margin: "50px",
                  marginTop: "50px",
                  padding: "25px",
                  fontSize: "2rem",
                  color: theme === "dark" ? "#000000" : "#ffffff",
                }}
              >
                Services
                <br />
                <br />
              </Navbar.Brand>
              <Navbar.Brand
                href="#about-me"
                style={{
                  margin: "50px",
                  marginTop: "50px",
                  padding: "25px",
                  fontSize: "2rem",
                  color: theme === "dark" ? "#000000" : "#ffffff",
                }}
              >
                About
                <br />
                <br />
              </Navbar.Brand>
              <br />
            </Offcanvas.Body>
          </Offcanvas>
        </>
      ) : (
        /* Navbar for medium screens and up */
        <Navbar
          fixed="top"
          expand="md"
          style={{ ...navbarStyle, padding: "10px 40px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginLeft: "30px",
              paddingTop: "2px",
            }}
          >
            {theme === "dark" ? (
              <Image
                width="51"
                height="60"
                src="Group 1321314423.png"
                onClick={handleScrollToHome}
              />
            ) : (
              <Image
                width="51"
                height="60"
                src="Subtract.png"
                onClick={handleScrollToHome}
              />
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
                paddingRight: "30px",
                paddingLeft: "30px",
              }}
            >
              <Navbar.Brand
                className="Nav-hover"
                href="#home "
                onClick={handleScrollToHome}
                style={{ ...brandStyle, margin: "0 20px" }}
              >
                Home
              </Navbar.Brand>
              <Navbar.Brand
                className="Nav-hover"
                href="#portfolio"
                style={{ ...brandStyle, margin: "0 20px" }}
              >
                Portfolio
              </Navbar.Brand>
              <Navbar.Brand
                className="Nav-hover"
                href="#services"
                style={{ ...brandStyle, margin: "0 20px" }}
              >
                Services
              </Navbar.Brand>
              <Navbar.Brand
                className="Nav-hover"
                href="#about-me"
                style={{ ...brandStyle, margin: "0 20px" }}
              >
                About
              </Navbar.Brand>
              <CustomSwitch theme={theme} toggleTheme={toggleTheme} />
            </div>
          </div>
        </Navbar>
      )}
    </div>
  );
}

export default NavBar;
