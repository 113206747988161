import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/navbar";
import TopSection from "./components/section1";
import { Container, Row } from "react-bootstrap";
import SectionMobile from "./components/sectionMobile";
import "./App.css";

function App() {
  const [theme, setTheme] = useState("light");
  const [isMobile, setIsMobile] = useState(window.outerWidth <= 900);

  // Clear theme from localStorage and reset to dark theme when the app loads
  useEffect(() => {
    localStorage.removeItem("theme"); // Clear any stored theme on page load
    setTheme("light"); // Reset to dark theme
  }, []);

  // Handle window resize for mobile responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.outerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Toggle the theme and save it in localStorage
  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme); // Save theme to localStorage (optional, but will clear on refresh)
  };

  // Apply theme to the document's root element
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <Container
      fluid
      style={{
        textDecoration: "none",
        overflow: "hidden",
        color: "white",
        backgroundColor: theme === "dark" ? "black" : "white", // Adjust color based on theme
        padding: "50px 0 80px",
        paddingRight: "0px",
        paddingBottom: "0px",
        margin: "0",
        maxWidth: "100%",
      }}
    >
      <NavBar theme={theme} toggleTheme={toggleTheme} />
      <Row noGutters style={{ paddingRight: "0px" }}>
        {isMobile ? (
          <SectionMobile theme={theme} toggleTheme={toggleTheme} />
        ) : (
          <TopSection theme={theme} toggleTheme={toggleTheme} />
        )}
      </Row>
    </Container>
  );
}

export default App;
