import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Container, Image, Button } from "react-bootstrap";
import { Row, Col, Card, Form } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import Footer from "./Footer";
import "./themes.css";
import ExpComp from "./animations/ExpComp";
import ExpComp2 from "./animations/ExpComp2";

function SectionMobile({ theme, toggleTheme }) {
  const [isMobile, setIsMobile] = useState(window.outerWidth <= 425);

  // Use effect to handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.outerWidth <= 425);
    };

    // Add the event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container
      fluid
      style={{
        height: "100%",
        marginTop: "10px",
        fontFamily: "Poppins",      
        backgroundColor:
          theme === "light"
            ? "#000000"
            : "#ffffff",
        color:
          theme === "light"
            ? "#ffffff"
            : "#000000",
      }}
    >
      {/* home */}
      <Row className="align-items-center" style={{ paddingTop: "80px" }}>
        <Col xs={12} md={6}>
          {/* Left Section with Image and Text */}
          <div
            style={{
              backgroundImage: "url('Group 36993.png')",
              backgroundSize: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "70px",
              paddingLeft: "10px",
            }}
          >
            {theme === "dark" ? (
              <Image
                fluid
                style={{
                  width: "85%",
                  paddingLeft: "0",
                  height: "auto",
                  marginTop: "45px",
                }}
                src="Group 11.png"
              />
            ) : (
              <Image
                fluid
                style={{ width: "180%", marginLeft: "0", height: "auto" }}
                src="S1.1.png"
              />
            )}
            <div
              style={{
                fontSize: "1rem",
                color:
                  theme ===
                  "light"
                    ? "#ffffff"
                    : "#000000",
                fontWeight: "lighter",
                letterSpacing: "1.2px",
                marginTop: "1.2rem",
                marginBottom: "50px",

                marginLeft: "8%",
              }}
            >
              I’m a Product Designer with 7+ years of experience crafting
              user-friendly, visually engaging digital products. I specialize in
              mobile apps, SaaS, and e-commerce design, blending creativity with
              functionality to deliver seamless user experiences.
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </Col>
        <Col xs={12} md={2}></Col>
        <Col xs={12} md={4}>
          {/* Right Section with Image */}
          <div
            style={{
              backgroundImage: "url('Group 1321314425.png')",
              backgroundSize: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginTop: "50px",
              paddingBottom: "100px",
            }}
          >
            <Image
              fluid
              style={{
                width: "100%",
                maxWidth: "374px",
                height: "auto",
              }}
              src="topPic.png"
            />
          </div>
        </Col>
      </Row>
      {/* experience */}
      <div
        style={{
          backgroundImage: "url('Ellipse 338.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "4rem",
          textAlign: "center",
          color: "white",
          position: "relative",
          // minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "0px",
          marginTop: "0px",
        }}
      >
        <div
          style={{
            width: "100%", // Allow container to be full width
            maxWidth: "800px",
            margin: "0 auto",
            color:
              theme === "light"
                ? "#ffffff"
                : "#000000",
          }}
        >
          <h1 style={{ fontSize: "3.5rem", fontWeight: "Bold" }}>
            <br />
            7+ YEARS XP
          </h1>
          <h2>From Software as a service to Design</h2>
          <h3
            style={{
              fontSize: "1rem",
              fontWeight: "lighter",
            }}
          >
            I’ve had the opportunity to work across all kinds of product
          </h3>
        </div>
        <br />
        <br />
        <br />
        <br />
        <ExpComp />
        <Row
          className="justify-content-center"
          style={{
            marginBottom: "70px",
            marginTop: "50px",
            marginLeft: "25px",
            marginRight: "25px",
          }}
        >
          <Col xs={12} sm={isMobile ? 8 : 6} lg={4} className="grid-item">
            <Card
              style={{
                width: "100%",
                background: "transparent",
                height: "auto",
                overflow: "hidden",
                marginBottom: "50px",
              }}
            >
              <Card.Img
                variant="top"
                src="R1.2.png"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </Card>
          </Col>

          <Col xs={12} sm={isMobile ? 8 : 6} lg={4} className="grid-item">
            <Card
              style={{
                width: "100%",
                background: "transparent",
                height: "auto",
                marginBottom: "50px",
                overflow: "hidden",
              }}
            >
              <Card.Img
                variant="top"
                src="R1.3.png"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </Card>
          </Col>

          <Col xs={12} sm={isMobile ? 8 : 6} lg={4} className="grid-item">
            <Card
              style={{
                width: "100%",
                background: "transparent",
                height: "auto",
                marginBottom: "50px",
                overflow: "hidden",
              }}
            >
              <Card.Img
                variant="top"
                src="R1.1.png"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </Card>
          </Col>
        </Row>

        <ExpComp2 />
      </div>
      {/* Portfolio */}
      <div
        id="portfolio"
        style={{
          backgroundImage: "url('Ellipse 338.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "10px",
          textAlign: "center",
          color: "white",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            marginTop: "150px ",
            paddingRight: "50px",
            color:
              theme === "light"
                ? "#ffffff"
                : "#000000",
          }}
        >
          <h1
            style={{
              fontSize: "3.5rem",
              fontWeight: "Bold",
              fontFamily: "'Poppins",
            }}
          >
            Creative Work Portfolio
          </h1>
          <h2
            style={{
              fontSize: "1rem",
              margin: "5px",
              lineHeight: "19px",
              letterSpacing: "3px",
              fontWeight: "lighter",
              textAlign: "center",
            }}
          >
            Dive into a visual journey of innovation and design excellence. From
            stunning UI/UX to captivating brand identities, witness a showcase
            of my diverse and impactful creative projects. Let's bring your
            vision to life!
            <br />
            <br />
            <br />
            <br />
          </h2>
        </div>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <div className="position-relative" style={{ height: "500px" }}>
              {/* Set a height for the carousel */}
              <Carousel indicators={false} style={{ height: "100%" }}>
                {" "}
                {/* Ensure the carousel takes full height */}
                <Carousel.Item style={{ height: "100%" }}>
                  <img
                    className="d-block mx-auto"
                    src="Property 1=Group 36982 (1).png"
                    alt="First slide"
                    style={{
                      width: "75%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item style={{ height: "100%" }}>
                  <img
                    className="d-block mx-auto"
                    src="Property 1=Group 36983 (1).png"
                    alt="Second slide"
                    style={{
                      width: "75%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item style={{ height: "100%" }}>
                  <img
                    className="d-block mx-auto"
                    src="Property 1=Group 36984 (1).png"
                    alt="Third slide"
                    style={{
                      width: "75%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Carousel.Item>
              </Carousel>
              {/* Button positioned at the bottom of the carousel */}
              <a
                href="https://www.behance.net/mmudassir2"
                target="_blank"
                rel="noopener noreferrer"
                className="position-absolute"
                style={{
                  marginBottom: "20px", // Adjusted to stick closer to the bottom of the carousel
                  left: "75%",
                  top: "65%",
                  marginTop: "14%",
                  transform: "translateX(-10%)",
                  textDecoration: "none", // Remove underline
                  fontSize: "1.6rem", // Adjust font size for 'lg'
                  color:
                  theme ===
                  "light"
                    ? "#ba7ce7"
                    : "#b02eba",
                }}
              >
                Visit Portfolio
                <i
                  class="bi bi-box-arrow-up-right"
                  style={{ marginLeft: "10px" }}
                ></i>
              </a>
            </div>
          </Col>
        </Row>
      </div>
      {/* Services */}
      <div
        id="services"
        style={{
          backgroundImage: "url('Ellipse 338.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "3px",
          marginTop: "0rem",
          textAlign: "center",
          justifyContent: "center",
          color: "white",
          marginBottom: "50px",
          paddingBottom: "100px",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            marginTop: "150px",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "0px",
            color:
              theme === "light"
                ? "#ffffff"
                : "#000000",
          }}
        >
          <h1
            style={{
              fontSize: "3.5rem",
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "50px",
            }}
          >
            SERVICES
          </h1>
          {theme === "light" ? (
            <div className="image-row">
              <Image src="Frame 42197.png" />
              <Image src="Frame 42198.png" />
              <Image src="Frame 42199.png" />
              <Image src="Frame 42200.png" />
              <Image src="Frame 42201.png" />
            </div>
          ) : (
            <div className="image-row">
              <Image src="Frame 2197.png" />
              <Image src="Frame 2198.png" />
              <Image src="Frame 2199.png" />
              <Image src="Frame 2200.png" />
              <Image src="Frame 2201.png" />
            </div>
          )}
        </div>
      </div>
      {/* Card Section */}
      <Row
        style={{
          width: "100%",
          textAlign: "center",
          marginBottom: "50px",
        }}
      >
        <h2
          style={{
            fontSize: "25px",
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Product, Website or Brand Design, I've got you covered!
        </h2>
        <h4
          style={{
            fontSize: "16px",
            width: "100%",
            textAlign: "center",
            fontWeight: "normal",
            color: "GrayText",
          }}
        >
          Let's elevate your vision and achieve your design goals together!
        </h4>
      </Row>
      <Row
        style={{
          margin: "60px",
          marginBottom: "30px",
          marginRight: "80px",
          marginLeft: "80px",
        }}
      >
        <Col xs={12} md={6} lg={3} style={{ padding: "5px" }}>
          <Card
            style={{
              position: "relative",
              overflow: "unset",
              width: "90%",
              background: "transparent",
              height: "90%",
            }}
          >
            <div className="hover-effect image-container">
              <Card.Img variant="top" src="span.png" />
            </div>
            <Card.Body
              style={{
                color:
                  theme ===
                  "light"
                    ? "#ffffff"
                    : "#000000",
              }}
            >
              <Card.Title style={{ fontSize: "1.75rem" }}>
                Web & App Design
              </Card.Title>
              <Card.Text>
                Get landing pages designed from scratch or based on existing
                materials with thoughtful UI/UX wireframes and designs.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={3} style={{ padding: "5px" }}>
          <Card
            style={{
              position: "relative",
              overflow: "unset",
              width: "90%",
              background: "transparent",
              height: "90%",
            }}
          >
            <div className="hover-effect image-container">
              <Card.Img variant="top" src="span-1.png" />
            </div>
            <Card.Body
              style={{
                color:
                  theme ===
                  "light"
                    ? "#ffffff"
                    : "#000000",
              }}
            >
              <Card.Title style={{ fontSize: "1.75rem" }}>
                Branding Services
              </Card.Title>
              <Card.Text>
                Get the brand expertise you need, from brand development to
                custom branding solutions.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={3} style={{ padding: "5px" }}>
          <Card
            style={{
              position: "relative",
              overflow: "unset",
              width: "90%",
              background: "transparent",
              height: "90%",
            }}
          >
            <div className="hover-effect image-container">
              <Card.Img variant="top" src="span-6.png" />
            </div>
            <Card.Body
              style={{
                color:
                  theme ===
                  "light"
                    ? "#ffffff"
                    : "#000000",
              }}
            >
              <Card.Title style={{ fontSize: "1.75rem" }}>
                Packaging and Merch
              </Card.Title>
              <Card.Text>
                Get on-brand apparel, merchandise, or packaging designs to stand
                out from the crowd.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={3} style={{ padding: "5px" }}>
          <Card
            style={{
              position: "relative",
              overflow: "unset",
              width: "90%",
              background: "transparent",
              height: "90%",
            }}
          >
            <div className="hover-effect image-container">
              <Card.Img variant="top" src="span-2.png" />
            </div>
            <Card.Body
              style={{
                color:
                  theme ===
                  "light"
                    ? "#ffffff"
                    : "#000000",
              }}
            >
              <Card.Title style={{ fontSize: "1.75rem" }}>
                Video Production
              </Card.Title>
              <Card.Text>
                Get strategy-aligned videos and video production services from
                pre-production to final editing.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row
        style={{
          margin: "60px",
          marginBottom: "30px",
          marginRight: "80px",
          marginLeft: "80px",
        }}
      >
        <Col xs={12} md={6} lg={3} style={{ padding: "5px" }}>
          <Card
            style={{
              position: "relative",
              overflow: "unset",
              width: "90%",
              background: "transparent",
              height: "90%",
            }}
          >
            <div className="hover-effect image-container">
              <Card.Img variant="top" src="span-3.png" />
            </div>
            <Card.Body
              style={{
                color:
                  theme ===
                  "light"
                    ? "#ffffff"
                    : "#000000",
              }}
            >
              <Card.Title style={{ fontSize: "1.75rem" }}>
                Social Media Creative
              </Card.Title>
              <Card.Text>
                Get original designs for your social media channels, whether
                static, animated, or video content.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3} style={{ padding: "5px" }}>
          <Card
            style={{
              position: "relative",
              overflow: "unset",
              width: "90%",
              background: "transparent",
              height: "90%",
            }}
          >
            <div className="hover-effect image-container">
              <Card.Img variant="top" src="span-4.png" />
            </div>
            <Card.Body
              style={{
                color:
                  theme ===
                  "light"
                    ? "#ffffff"
                    : "#000000",
              }}
            >
              <Card.Title style={{ fontSize: "1.75rem" }}>3D Design</Card.Title>
              <Card.Text>
                Push creative boundaries with social AR and 3D design to capture
                your audience’s attention.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3} style={{ padding: "5px" }}>
          <Card
            style={{
              position: "relative",
              overflow: "unset",
              width: "90%",
              background: "transparent",
              height: "90%",
            }}
          >
            <div className="hover-effect image-container">
              <Card.Img variant="top" src="span-5.png" />
            </div>
            <Card.Body
              style={{
                color:
                  theme ===
                  "light"
                    ? "#ffffff"
                    : "#000000",
              }}
            >
              <Card.Title style={{ fontSize: "1.75rem" }}>
                Motion Design
              </Card.Title>
              <Card.Text>
                Get on-brand motion graphics for websites, digital campaigns,
                presentations, and ads.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3} style={{ padding: "5px" }}>
          <Card
            style={{
              position: "relative",
              overflow: "unset",
              width: "90%",
              background: "transparent",
              height: "90%",
            }}
          >
            <div className="hover-effect image-container">
              <Card.Img variant="top" src="span-7.png" />
            </div>
            <Card.Body
              style={{
                color:
                  theme ===
                  "light"
                    ? "#ffffff"
                    : "#000000",
              }}
            >
              <Card.Title style={{ fontSize: "1.75rem" }}>
                Illustration Design
              </Card.Title>
              <Card.Text>
                Get custom illustrations for your business or marketing that
                extend your brand's identity.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Voices of design magic */}
      <div
        style={{
          backgroundImage: "url('Ellipse 338.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "0",
          textAlign: "center",
          color: "white",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            marginTop: "150px",
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "0px",
            color:
              theme === "light"
                ? "#ffffff"
                : "#000000",
          }}
        >
          <h1
            style={{
              fontSize: "3.5rem",
              width: "100%",
              textAlign: "center",
              marginBottom: "50px",
              fontWeight: "Bold",
            }}
          >
            Voices of design magic
          </h1>
          <h2
            style={{
              fontSize: "1.2rem",
              margin: "0 30px",
              color:"GrayText",
              fontWeight: "lighter",
            }}
          >
            Stories from Clients Client's Who Dreamed Big and Watched Their
            Visions Come to Life through
          </h2>
        </div>
        <div
          style={{
            marginTop: "100px",
            backgroundImage: "url('BG12.png')",
            backgroundSize: "85%", // Adjust size of the background image
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat", // Prevent background image from repeating
            padding: "2rem", // Adjust padding for a smaller background image
            width: "100%",
            textAlign: "center",
            color: "white",
            overflow: "hidden", // Prevent overflow of the images
          }}
        >
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Carousel
                style={{ margin: "60px", overflow: "hidden" }}
                indicators={false}
                //slide={true}
              >
                <Carousel.Item>
                  <Row
                    className="no-gutters align-items-center "
                    style={{ margin: "0", padding: "0" }}
                  >
                    <Col
                      className="d-flex"
                      style={{
                        padding: "0",
                        margin: "0",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        className="d-block"
                        alt="First slide"
                        src="C1 (1).png"
                        style={{
                          width: "50%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                    <Col
                      className="d-flex"
                      style={{
                        padding: "0",
                        margin: "0",
                        justifyContent: "left",
                      }}
                    >
                      <img
                        alt="Second slide"
                        src="Testimonial.png"
                        style={{
                          width: "70%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row
                    className="no-gutters align-items-center"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <Col
                      className="d-flex justify-content-center"
                      style={{ padding: "0", margin: "0" }}
                    >
                      <img
                        className="d-block"
                        alt="First slide"
                        src="Mask group-1.png"
                        style={{
                          width: "50%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                    <Col
                      className="d-flex "
                      style={{
                        padding: "0",
                        margin: "0",
                        justifyContent: "left",
                      }}
                    >
                      <img
                        alt="Second slide"
                        src="Testimonial-1.png"
                        style={{
                          width: "70%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row
                    className="no-gutters align-items-center"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <Col
                      className="d-flex justify-content-center"
                      style={{ padding: "0", margin: "0" }}
                    >
                      <img
                        className="d-block"
                        alt="First slide"
                        src="Mask group-2.png"
                        style={{
                          width: "50%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                    <Col
                      className="d-flex "
                      style={{
                        padding: "0",
                        margin: "0",
                        justifyContent: "left",
                      }}
                    >
                      <img
                        alt="Second slide"
                        src="Testimonial-2.png"
                        style={{
                          width: "70%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row
                    className="no-gutters align-items-center"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <Col
                      className="d-flex justify-content-center"
                      style={{ padding: "0", margin: "0" }}
                    >
                      <img
                        className="d-block"
                        alt="First slide"
                        src="Mask group-3.png"
                        style={{
                          width: "50%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                    <Col
                      className="d-flex "
                      style={{
                        padding: "0",
                        margin: "0",
                        justifyContent: "left",
                      }}
                    >
                      <img
                        alt="Second slide"
                        src="Testimonial-3.png"
                        style={{
                          width: "70%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row
                    className="no-gutters align-items-center"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <Col
                      className="d-flex justify-content-center"
                      style={{ padding: "0", margin: "0" }}
                    >
                      <img
                        className="d-block"
                        alt="First slide"
                        src="Mask group-4.png"
                        style={{
                          width: "50%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                    <Col
                      className="d-flex "
                      style={{
                        padding: "0",
                        margin: "0",
                        justifyContent: "left",
                      }}
                    >
                      <img
                        alt="Second slide"
                        src="Testimonial-4.png"
                        style={{
                          width: "70%",
                          height: "auto",
                          objectFit: "cover",
                          margin: "0",
                        }} // Ensure images fit within the container
                      />
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </div>
      </div>

      <Row
        className="align-items-center"
        style={{ paddingTop: "150px", marginBottom: "50px" }}
      >
        <Col sm={12} md={11} lg={11}>
          <Row
            style={{
              backgroundImage: "url('bg Element2.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "110%",
              backgroundColor: "transparent",
              marginLeft: "70px",
              padding: "10px",
              color:
              theme === "dark"
                ? "#000000"
                : "#ffffff",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Col xs={12} md={6} lg={6}>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  fontSize: "1.5rem",
                  alignItems: "baseline",
                }}
              >
                What makes my design services different?
              </h3>

              <h5
                style={{
                  fontWeight: "lighter",

                  fontSize: "0.9rem",
                  letterSpacing: "1px",
                  lineHeight: "25px",

                  padding: "10px",
                }}
              >
                Designing Beyond Boundaries:
                <br /> What sets my services apart is the fusion of imagination
                with a touch of magic. Each project is an enchanting journey,
                where creativity dances with innovation. I turn your concepts
                into captivating realities that transcend the ordinary. With a
                commitment to detail and a passion for pushing creative
                boundaries, I ensure that your brand's story unfolds in a way
                that captivates and inspires. Let's create design magic that's
                uniquely yours and sets your brand apart from the rest.
              </h5>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              style={{ position: "relative", height: "400px", padding: "0" }}
            >
              {/* First image in the back */}
              <Image
                fluid
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  zIndex: 1,
                }}
                src="giphy (1) 1.gif"
              />

              {/* Second image in the middle */}
              <Image
                fluid
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "20%", // Adjust width for responsiveness
                  height: "30%",
                  zIndex: 2,
                }}
                src="Subtract.png"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={1} md={1} lg={1}></Col>
      </Row>
      {/* About Me */}
      <Row
        id="about-me"
        style={{
          paddingTop: "60px",
          backgroundImage: "url('bg Element.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "110%",
        }}
      >
        <Col xs={4} md={6} lg={6} style={{}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <br />
            <h1
              style={{
                color:
                  theme === "dark"
                    ? "#000000"
                    : "#ffffff",
                fontWeight: "Bold",
                fontSize: "2.5rem",
              }}
            >
              ABOUT ME
            </h1>
            <Image
              fluid
              style={{ width: "40%", marginTop: "100px", height: "30%" }}
              src="MD.png"
            />
            <br /> <br />
            <br /> <br />
            <br />
          </div>
        </Col>
        <Col xs={8} md={6} lg={6}>
          <div
            style={{
              backgroundColor: "transparent",
              marginRight: "50px",
              paddingRight: "50px",
              paddingTop: "25px",
            }}
          >
            <Row>
              {theme ===
              "light" ? (
                <Image
                  fluid
                  style={{
                    width: "65%",
                    paddingLeft: "50px",
                    height: "auto",
                    paddingTop: "60px",
                  }}
                  src="I’m Mudasir Re..png"
                />
              ) : (
                <Image
                  fluid
                  style={{
                    width: "65%",
                    paddingLeft: "50px",
                    height: "auto",
                    paddingTop: "60px",
                  }}
                  src="I’m Mudasir Re.-1.png"
                />
              )}
            </Row>
            <Row>
              <h3
                style={{
                  fontWeight: "lighter",
                  backgroundColor: "transparent",
                  marginLeft: "50px",
                  marginTop: "10px",
                  marginRight: "85px",
                  width: "80%", // Match text width to image

                  lineHeight: "29px",
                  color:
                    theme ===
                    "dark"
                      ? "#000000"
                      : "#ffffff",
                  fontSize: "0.9rem",
                  letterSpacing: "1px",
                  paddingRight: "30px",
                }}
              >
                <br />
                <br />
                <br />
                My journey in the world of design began 7 years ago with a
                fascination for the digital realm. From my early days of
                curiosity to today’s expertise, I’ve honed the art of
                transforming ideas into captivating designs, mastering the
                creation of design solutions that bridge the gap between
                aesthetics and functionality.
                <br />
                <br />
              </h3>
            </Row>
            <Row className="justify-content-end">
              {theme ===
              "light" ? (
                <Image
                  fluid
                  style={{
                    width: "50%",
                    paddingRight: "80px",
                    marginRight: "40px",
                    height: "auto",
                    paddingBottom: "100px",
                  }}
                  src="IMG_7963 2.png"
                />
              ) : (
                <Image
                  fluid
                  style={{
                    width: "50%",
                    paddingRight: "80px",
                    marginRight: "40px",
                    height: "auto",
                    paddingBottom: "50px",
                  }}
                  src="IMG_7963 3.png"
                />
              )}
            </Row>
          </div>
          <br /> <br />
        </Col>
      </Row>
      <br /> <br />
      {/* Contact Me */}
      <div
        style={{
          backgroundColor:
            theme === "light"
              ? "#000000"
              : "#ffffff",
          color:
            theme === "light"
              ? "#ffffff"
              : "#000000",
          padding: "60px 0",
          textAlign: "left",
          lineHeight: "5px",
        }}
      >
        <Container>
          <h2
            style={{
              fontSize: "3.5rem",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "80px",
              maxWidth: "900px",
            }}
          >
            CONTACT ME
          </h2>
          <Row>
            <Col xs={5} md={4} lg={4}>
              <Row
                style={{
                  marginBottom: "50px",
                }}
              >
                <Col xs={4} md={4} lg={4}>
                  <Image
                    fluid
                    style={{
                      width: "55%",
                      marginTop: "25px",
                      height: "auto",
                    }}
                    src="location-pin-svgrepo-com 2.png"
                  />
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <h3 style={{ marginBottom: "0", marginTop: "25px" }}>
                    OFFICE
                  </h3>
                  <br />
                  <h5
                    style={{
                      fontWeight: "lighter",
                      fontSize: "12px",
                      color: "GrayText",
                      marginTop: "0",
                    }}
                  >
                    Lahore, Pakistan
                  </h5>
                </Col>
              </Row>
              <Row style={{ marginBottom: "50px" }}>
                <Col xs={4} md={4} lg={4}>
                  <Image
                    fluid
                    style={{
                      width: "55%",
                      marginTop: "25px",
                      height: "auto",
                    }}
                    src="mail-reception-svgrepo-com 1.png"
                  />
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <h3 style={{ marginBottom: "0", marginTop: "25px" }}>MAIL</h3>
                  <br />
                  <h5
                    style={{
                      fontWeight: "lighter",
                      color: "GrayText",
                      fontSize: "12px",
                      marginTop: "0",
                    }}
                  >
                    mudassir1839@gmail.com
                  </h5>
                </Col>
              </Row>
              <Row style={{ marginBottom: "50px" }}>
                <Col xs={4} md={4} lg={4}>
                  <Image
                    fluid
                    style={{
                      width: "55%",
                      marginTop: "25px",
                      height: "auto",
                    }}
                    src="222222.png"
                  />
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <h3 style={{ marginBottom: "0", marginTop: "25px" }}>
                    PHONE
                  </h3>
                  <br />
                  <h5
                    style={{
                      fontWeight: "lighter",
                      color: "GrayText",
                      fontSize: "12px",
                      marginTop: "0",
                    }}
                  >
                    +92 323 4640 630
                  </h5>
                </Col>
              </Row>
            </Col>
            <Col xs={2} md={2} lg={2}>
              <div
                style={{
                  height: "600px",
                  width: "0.5px",
                  backgroundColor:
                    theme ===
                    "light"
                      ? "#ffffff"
                      : "#000000",
                  margin: "0 20px", // Spacing around the line
                }}
              />
            </Col>
            <Col xs={5} md={6} lg={6}>
            <Form style={{ backgroundColor: "transparent" }}>
                      <Form.Group controlId="formName">
                        <Form.Control
                          type="text"
                          placeholder="Your Name"
                          className="theme-input"
                        />
                      </Form.Group>
                      <Form.Group controlId="formEmail">
                        <Form.Control
                          type="email"
                          placeholder="Your Phone Number (Optional)"
                           className="theme-input"
                        />
                      </Form.Group>
                      <Form.Group controlId="formMessage">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Your Message"
                            className="theme-input"
                        />
                      </Form.Group>
                      <Form.Group controlId="formCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="I’m okay with getting emails and having that activity tracked to improve my experience."
                           className="theme-checkbox"
                          custom
                        />
                      </Form.Group>

                      <div>
                        <Button
                          variant="light"
                          style={{
                            padding: "15px 50px",
                            backgroundColor: "#3a0381",
                            color: "white",
                            border: "none",
                            marginLeft: "auto",
                            display: "block",
                          }}
                        >
                          Send Message
                        </Button>
                      </div>
                    </Form>
            </Col>
          </Row>
          <Row>
            <br />
            <br />
            <br />
          </Row>
        </Container>
      </div>
      <Footer />
    </Container>
  );
}
export default SectionMobile;
