import React from "react";
import "./SliderComp.css";

function ExpComp() {
  return (
    <div>
      <div className="wrapper">
        <div className="photobanner right" style={{marginBottom:"80px", paddingBottom:"20px"}}>
          <img src="Fit-Focus-logo-2 1.png" alt="" />
          <img src="Group 1 56.png" alt="" />
          <img src="Group 2.png" alt="" />
          <img src="Group 239 1.png" alt="" />
          <img src="Group 688.png" alt="" />
          <img src="Group 36958.png" alt="" />
          <img src="Vector.png" alt="" />
          <img src="Group 1321314402.png" alt="" />
          <img src="Group 1321314403.png" alt="" />
          <img src="Group 1321314404.png" alt="" />
          <img src="Fit-Focus-logo-2 1.png" alt="" />
          <img src="Group 1 56.png" alt="" />
          <img src="Group 2.png" alt="" />
          <img src="Group 1321314406.png" alt="" />
          <img src="Group 1321314407.png" alt="" />
          <img src="Group-1.png" alt="" />
          <img src="Group 1321314404.png" alt="" />
        </div>
      </div>

      <div className="wrapper">
        <div className="photobanner left" style={{marginBottom:"50px"}}>
          <img src="Group 1321314405.png" alt="" />
          <img src="Group.png" alt="" />
          <img src="Vector-1.png" alt="" />
          <img src="Logo-Dark 3.png" alt="" />
          <img src="Mask group.png" alt="" />
          <img src="Vector-2.png" alt="" />
          <img src="Group 239 1.png" alt="" />
          <img src="Group 688.png" alt="" />
          <img src="Group 36958.png" alt="" />
          <img src="Group 1321314404.png" alt="" />
          <img src="Fit-Focus-logo-2 1.png" alt="" />
          
        </div>
      </div>
    </div>
  );
}

export default ExpComp;
