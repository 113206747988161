import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./themes.css";

function Footer() {
  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        marginBottom: "0",
        backgroundColor: "#2D0027",
        color: "white",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "100%",
      }}
    >
      <Container>
        <Row
          className="text-center"
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col
            xs={4}
            md={4}
            lg={4}
            style={{
              marginBottom: "25px",
              marginTop: "25px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: "1rem", marginBottom:"0" }}>
              Copyright © 2024 . All rights reserved.
            </p>
          </Col>
          <Col xs={2} md={2} lg={2}></Col>
          <Col
            xs={2}
            md={2}
            lg={2}
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <p style={{ fontSize: "1rem" }}>Privacy policy</p>
          </Col>
          <Col
            xs={1}
            md={1}
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <p style={{ fontSize: "1rem" }}>Cookies</p>
          </Col>
          <Col
            xs={3}
            md={3}
            lg={3}
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <p style={{ fontSize: "1rem" }}>Terms & Conditions</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
