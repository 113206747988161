import React from "react";
import "./ExpComp2.css";

function ExpComp2() {
  return (
    <div className="dock">
      <div className="dock-container">
        <ul>
          <li className="li-1">
            <div className="name">Slack</div>
            <img className="ico" src="Apps-1.png" alt="Slack" />
          </li>
          <li className="li-2">
            <div className="name">Notion</div>
            <img className="ico" src="Apps.png" alt="Notion" />
          </li>
          <li className="li-3">
            <div className="name">PhotoShop</div>
            <img className="ico" src="Apps-2.png" alt="PhotoShop" />
          </li>
          <li className="li-4">
            <div className="name">Illustrator</div>
            <img className="ico" src="Apps-3.png" alt="Illustrator" />
          </li>
          <li className="li-5">
            <div className="name">Sketch</div>
            <img className="ico" src="Apps-4.png" alt="Sketch" />
          </li>
          <li className="li-6">
            <div className="name">Figma</div>
            <img className="ico" src="Apps-5.png" alt="Figma" />
          </li>
          <li className="li-7">
            <div className="name">Framer</div>
            <img className="ico" src="Apps-6.png" alt="Framer" />
          </li>
          <li className="li-bin">
            <div className="name">Bin</div>
            <img className="ico" src="Apps-7.png" alt="Bin" />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ExpComp2;
