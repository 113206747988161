import React from "react";
import "./CustomSwitch.css";

const CustomSwitch = ({ theme, toggleTheme }) => {
  return (
    <div className="custom-switch-container" onClick={toggleTheme}>
      {theme === "dark" ? (
        <img
          src="Group 1321314435.png"
          alt="Light Mode Icon"
          className="custom-switch-icon"
        />
      ) : (
        <img
          src="Group 1321314431.png"
          alt="Dark Mode Icon"
          className="custom-switch-icon"
        />
      )}
    </div>
  );
};

export default CustomSwitch;
